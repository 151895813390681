import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-db360dac"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "task-add"
};
var _hoisted_2 = {
  style: {
    "display": "flex",
    "flex-direction": "row",
    "width": "100%",
    "justify-content": "center",
    "align-items": "center"
  }
};
var _hoisted_3 = {
  ref: "uploadRef",
  "class": "document-upload"
};
var _hoisted_4 = {
  "class": "document-files"
};
var _hoisted_5 = {
  style: {
    "flex": "1"
  }
};
var _hoisted_6 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_switch = _resolveComponent("el-switch");
  var _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    ref: "elFormRef",
    style: {
      "width": "30rem"
    }
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "任务名称",
        prop: "assignmentsName"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.taskData.assignmentsName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.taskData.assignmentsName = $event;
            }),
            maxlength: "64",
            placeholder: "请输入(1-64)的任务名称",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "设备领用",
        prop: "assignmentsDrones"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DroneSelector"], {
            modelValue: $setup.taskData.assignmentsDrones,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $setup.taskData.assignmentsDrones = $event;
            }),
            excludegatewayDomain: $setup.isDockAssignments ? '3' : '2'
          }, null, 8 /* PROPS */, ["modelValue", "excludegatewayDomain"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "任务成员",
        prop: "assignmentsMembers"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["MemberSelector"], {
            modelValue: $setup.taskData.assignmentsMembers,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $setup.taskData.assignmentsMembers = $event;
            }),
            disable: $setup.self
          }, null, 8 /* PROPS */, ["modelValue", "disable"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "配件领用"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DronePartsSelector"], {
            modelValue: $setup.taskData.assignmentsDroneParts,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $setup.taskData.assignmentsDroneParts = $event;
            })
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "任务密钥"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_input, {
            disabled: !$setup.taskData.assignmentsVerification,
            modelValue: $setup.taskData.assignmentsVerificationCode,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
              return $setup.taskData.assignmentsVerificationCode = $event;
            }),
            maxlength: "64",
            placeholder: "请输入(1-64)的任务名称",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["disabled", "modelValue"]), _createVNode(_component_el_switch, {
            style: {
              "margin-left": "1rem"
            },
            modelValue: $setup.taskData.assignmentsVerification,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
              return $setup.taskData.assignmentsVerification = $event;
            })
          }, null, 8 /* PROPS */, ["modelValue"])])];
        }),
        _: 1 /* STABLE */
      }), _withDirectives(_createElementVNode("view", null, [_createCommentVNode("        <el-form-item label=\"开始时间\" prop=\"assignmentsBeginTime\">"), _createCommentVNode("          <deep-date-picker"), _createCommentVNode("            v-model=\"taskData.assignmentsBeginTime\""), _createCommentVNode("            type=\"datetime\""), _createCommentVNode("            style=\"width: 100%\""), _createCommentVNode("            :disabledDate=\"disabledDate\""), _createCommentVNode("          ></deep-date-picker>"), _createCommentVNode("        </el-form-item>"), _createCommentVNode("        <el-form-item label=\"任务地点\" prop=\"assignmentsAddress\">"), _createCommentVNode("          <div style=\"display: flex;flex-direction: row;width: 100%;justify-content: center;align-items: center\">"), _createCommentVNode("            <el-input v-model=\"taskData.assignmentsAddress!.address\" maxlength=\"64\" placeholder=\"请选择任务地点\""), _createCommentVNode("                      show-word-limit"), _createCommentVNode("                      style=\"flex: 1\" @input=\"addressChange\"></el-input>"), _createCommentVNode("            <DeepSvgIcon remote name=\"local\" style=\"height: 2rem;width: 2rem;padding: .5rem\" @click.stop=\"showOrHideMap\"></DeepSvgIcon>"), _createCommentVNode("          </div>"), _createCommentVNode("        </el-form-item>"), _createElementVNode("div", _hoisted_3, " 上传任务数据 ", 512 /* NEED_PATCH */), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectFiles, function (item) {
        return _openBlock(), _createElementBlock("div", {
          "class": "file-item",
          key: item.name
        }, [_createElementVNode("text", _hoisted_5, _toDisplayString(item.name), 1 /* TEXT */), _createVNode($setup["DeepSvgIcon"], {
          "class": "delete",
          name: "close",
          onClick: function onClick($event) {
            return $setup.deleteFile(item);
          }
        }, null, 8 /* PROPS */, ["onClick"])]);
      }), 128 /* KEYED_FRAGMENT */))])], 512 /* NEED_PATCH */), [[_vShow, $setup.isReservationAssignments]]), _createElementVNode("div", _hoisted_6, [_createVNode($setup["DeepButton"], {
        size: "small",
        loading: $setup.loading,
        type: "primary",
        onClick: $setup.onSubmit
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("提交")];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading"]), _createVNode($setup["DeepButton"], {
        size: "small",
        onClick: $setup.onClose
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("取消")];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */), $setup.mapShow ? (_openBlock(), _createBlock($setup["MapSelector"], {
    key: 0,
    "class": "task-map",
    modelValue: $setup.taskData.assignmentsAddress,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $setup.taskData.assignmentsAddress = $event;
    })
  }, null, 8 /* PROPS */, ["modelValue"])) : _createCommentVNode("v-if", true)]);
}