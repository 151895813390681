import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cee6e792"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "dialog-ota-upgrade"
};
var _hoisted_2 = {
  "class": "firmware-list"
};
var _hoisted_3 = {
  "class": "progress"
};
var _hoisted_4 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_checkbox = _resolveComponent("el-checkbox");
  var _component_el_progress = _resolveComponent("el-progress");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dockFirmwares, function (item) {
    return _openBlock(), _createBlock($setup["DeepCell"], {
      title: item.firmwareName,
      label: item.firmwareVersion,
      key: item.firmwareId,
      "show-divider": ""
    }, {
      "default": _withCtx(function () {
        return [_createVNode(_component_el_checkbox, {
          disabled: $setup.isUpgrade,
          onChange: function onChange($event) {
            return $setup.handleCurrentChange(item.firmwareId);
          },
          "model-value": $setup.currentChecked === item.firmwareId
        }, null, 8 /* PROPS */, ["disabled", "onChange", "model-value"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "label"]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_3, [$setup.isUpgrade ? (_openBlock(), _createBlock(_component_el_progress, {
    key: 0,
    indeterminate: "",
    "stroke-width": 16,
    style: {
      "width": "100%",
      "margin-bottom": "1rem"
    },
    status: $setup.upgradePercent === 100 ? 'success' : 'warning',
    percentage: $setup.upgradePercent
  }, null, 8 /* PROPS */, ["status", "percentage"])) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString($setup.upgradeStatus), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_4, [_createVNode($setup["DeepButton"], {
    "class": "submit",
    type: "primary",
    size: "small",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $setup.submit(3);
    }),
    disabled: !$setup.upgradeable,
    loading: $setup.isUpgrade
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("普通升级")];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled", "loading"]), _createVNode($setup["DeepButton"], {
    "class": "submit",
    type: "primary",
    size: "small",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $setup.submit(2);
    }),
    disabled: !$setup.upgradeable,
    loading: $setup.isUpgrade
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("一致性升级")];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled", "loading"]), _createVNode($setup["DeepButton"], {
    "class": "cancel",
    size: "small",
    onClick: $setup.cancel
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("取消")];
    }),
    _: 1 /* STABLE */
  })])]);
}