import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "elFormRef"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "日志描述",
        prop: "journalDescribe"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.form.journalDescribe,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.form.journalDescribe = $event;
            }),
            maxlength: "128",
            placeholder: "请输入日志描述(1-128)",
            type: "textarea",
            autosize: {
              minRows: 4,
              maxRows: 4
            },
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepButton"], {
        type: "primary",
        size: "small",
        onClick: $setup.onSubmit
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("提交")];
        }),
        _: 1 /* STABLE */
      }), _createVNode($setup["DeepButton"], {
        size: "small",
        onClick: $setup.onClose
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("取消")];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */);
}