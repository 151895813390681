import "core-js/modules/es.array.concat.js";
import { createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8b1ca56e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "business-version"
};
var _hoisted_2 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepRowText"], {
    title: "执行设备",
    desc: (_a = $setup.drone) === null || _a === void 0 ? void 0 : _a.droneName,
    span: 1,
    style: {
      "margin-bottom": ".5rem"
    },
    flex: ""
  }, null, 8 /* PROPS */, ["desc"]), _createVNode($setup["DeepRowText"], {
    title: "执行目的地",
    desc: "".concat($setup.target.lat.toFixedCustom(7), "\xB0N,").concat($setup.target.lng.toFixedCustom(7), "\xB0E"),
    style: {
      "margin-bottom": ".5rem"
    },
    flex: ""
  }, null, 8 /* PROPS */, ["desc"]), _createVNode($setup["DeepRowText"], {
    title: "执行高度(相对高度)",
    style: {
      "margin-bottom": ".5rem"
    },
    flex: ""
  }, {
    desc: _withCtx(function () {
      return [_createVNode($setup["DeepCounterVIew"], {
        modelValue: $setup.targetHeight,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.targetHeight = $event;
        }),
        style: {
          "height": "1.6rem",
          "width": "10rem"
        },
        unit: "m",
        max: 1500,
        min: 1
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepRowText"], {
    title: "执行最大速度",
    style: {
      "margin-bottom": ".5rem"
    },
    flex: ""
  }, {
    desc: _withCtx(function () {
      return [_createVNode($setup["DeepCounterVIew"], {
        modelValue: $setup.maxSpeed,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $setup.maxSpeed = $event;
        }),
        style: {
          "height": "1.6rem",
          "width": "10rem"
        },
        unit: "m",
        max: 15,
        min: 1
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepRowText"], {
    title: "执行安全起飞高度",
    style: {
      "margin-bottom": ".5rem"
    },
    flex: ""
  }, {
    desc: _withCtx(function () {
      return [_createVNode($setup["DeepCounterVIew"], {
        modelValue: $setup.securityTakeoffHeight,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $setup.securityTakeoffHeight = $event;
        }),
        style: {
          "height": "1.6rem",
          "width": "10rem"
        },
        unit: "m",
        max: 1500,
        min: 1
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepRowText"], {
    title: "执行返航高度",
    style: {
      "margin-bottom": ".5rem"
    },
    flex: ""
  }, {
    desc: _withCtx(function () {
      return [_createVNode($setup["DeepCounterVIew"], {
        modelValue: $setup.rthAltitude,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $setup.rthAltitude = $event;
        }),
        style: {
          "height": "1.6rem",
          "width": "10rem"
        },
        unit: "m",
        max: 1500,
        min: 1
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_2, [_createVNode($setup["DeepButton"], {
    type: "primary",
    size: "small",
    onClick: $setup.onSubmit
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("起飞")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepButton"], {
    size: "small",
    onClick: $setup.onClose
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("取消")];
    }),
    _: 1 /* STABLE */
  })])]);
}