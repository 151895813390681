import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d6ac9b00"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "elFormRef",
    "class": "drone-add"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "图标"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepImageGrid"], {
            onListChange: $setup.imageChange,
            limitNum: 1
          })];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "飞机名称",
        prop: "droneName"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.form.droneName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.form.droneName = $event;
            }),
            maxlength: "16",
            placeholder: "请输入飞机名称",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "遥控型号",
        prop: "gatewayEnumKey"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DeviceEnumSelector"], {
            modelValue: $setup.form.gatewayEnumKey,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $setup.form.gatewayEnumKey = $event;
            }),
            include: "2"
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), !$setup.selectDroneTemp ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0,
        label: "遥控序列号",
        prop: "gatewaySerialNumber"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.form.gatewaySerialNumber,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $setup.form.gatewaySerialNumber = $event;
            }),
            maxlength: "32",
            placeholder: "请输入遥控序列号",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepButton"], {
        type: "primary",
        size: "small",
        onClick: $setup.onSubmit
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("提交")];
        }),
        _: 1 /* STABLE */
      }), _createVNode($setup["DeepButton"], {
        size: "small",
        onClick: $setup.onClose
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("取消")];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */);
}