import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_switch = _resolveComponent("el-switch");
  var _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "elFormRef",
    "label-width": "80px"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "版本名称",
        prop: "businessName"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.data.businessName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.data.businessName = $event;
            }),
            maxlength: "8",
            placeholder: "请输入版本名称",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "成员数量",
        prop: "memberNumLimit"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.data.memberNumLimit,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $setup.data.memberNumLimit = $event;
            }),
            type: "number",
            placeholder: "请输入成员数量",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "飞机数量",
        prop: "deviceNumLimit"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.data.deviceNumLimit,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $setup.data.deviceNumLimit = $event;
            }),
            type: "number",
            placeholder: "请输入飞机数量",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "任务数量",
        prop: "assignmentsNumLimit"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.data.assignmentsNumLimit,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $setup.data.assignmentsNumLimit = $event;
            }),
            type: "number",
            placeholder: "请输入任务数量",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "网盘容量",
        prop: "diskVolumeLimit"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.data.diskVolumeLimit,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
              return $setup.data.diskVolumeLimit = $event;
            }),
            type: "number",
            placeholder: "请输入网盘容量(单位MB)",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "直播时长",
        prop: "liveNumLimit"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.data.liveNumLimit,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
              return $setup.data.liveNumLimit = $event;
            }),
            maxlength: "10",
            placeholder: "请输入直播时长(*分钟/d｜m｜y)",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "设置logo"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_switch, {
            modelValue: $setup.data.logoCustom,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
              return $setup.data.logoCustom = $event;
            })
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepButton"], {
        size: "small",
        loading: $setup.loading,
        type: "primary",
        onClick: _cache[7] || (_cache[7] = function ($event) {
          return $setup.onSubmit();
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("创建")];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading"]), _createVNode($setup["DeepButton"], {
        size: "small",
        onClick: $setup.handleClose
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("取消")];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */);
}