import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-70df2f2d"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "assignments-situation-upload"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_option = _resolveComponent("el-option");
  var _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
    type: "textarea",
    autosize: {
      minRows: 4,
      maxRows: 4
    },
    modelValue: $setup.situation,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.situation = $event;
    }),
    maxlength: "128",
    "show-word-limit": "",
    placeholder: "故障内容",
    "class": "textarea"
  }, null, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_el_select, {
    placeholder: "选择设备",
    modelValue: $setup.deviceSelect,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $setup.deviceSelect = $event;
    })
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.assignmentsDeviceSelect, function (item) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          value: item.value,
          label: item.label
        }, null, 8 /* PROPS */, ["value", "label"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]), _createVNode($setup["DeepButton"], {
    "class": "submit",
    type: "primary",
    size: "small",
    onClick: $setup.submit
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("上报")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepButton"], {
    "class": "cancel",
    size: "small",
    onClick: $setup.cancel
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("取消")];
    }),
    _: 1 /* STABLE */
  })]);
}