import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "elFormRef"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "执照名称",
        prop: "licenseName",
        style: {
          "width": "100%"
        }
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.form.licenseName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.form.licenseName = $event;
            }),
            maxlength: "32",
            minlength: "0",
            placeholder: "请输入(0-32)执照名称",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "执照编号",
        prop: "licenseNo"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.form.licenseNo,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $setup.form.licenseNo = $event;
            }),
            maxlength: "64",
            minlength: "6",
            placeholder: "请输入(0-64)的执照编号",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "执照生效日期",
        prop: "effectiveTime"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepDatePicker"], {
            modelValue: $setup.form.effectiveTime,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $setup.form.effectiveTime = $event;
            })
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "执照过期日期",
        prop: "expiryTime"
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepDatePicker"], {
            modelValue: $setup.form.expiryTime,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $setup.form.expiryTime = $event;
            })
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepButton"], {
        size: "small",
        loading: $setup.loading,
        type: "primary",
        onClick: _cache[4] || (_cache[4] = function ($event) {
          return $setup.onSubmit();
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("上传")];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading"]), _createVNode($setup["DeepButton"], {
        size: "small",
        onClick: $setup.handleClose
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("取消")];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */);
}